import { ReactNode } from 'react';

import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormGroupProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
} from '@mui/material';

import { Field, FieldProps } from 'react-final-form';
import { ErrorMessage, showErrorOnChange, useFieldForErrors, ShowErrorFunc } from './Util';

export interface SwitchData {
  label: ReactNode;
  value: unknown;
  disabled?: boolean;
}

export interface SwitchesProps extends Partial<Omit<MuiSwitchProps, 'onChange'>> {
  name: string;
  data: SwitchData | SwitchData[];
  label?: ReactNode;
  required?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  formGroupProps?: Partial<FormGroupProps>;
  formLabelProps?: Partial<FormLabelProps>;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
}

export function Switches(props: SwitchesProps): React.ReactElement {
  const {
    name,
    data,
    label,
    required,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    ...restSwitches
  } = props;

  const itemsData = Array.isArray(data) ? data : [data];
  const single = !Array.isArray(data);
  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} {...formControlProps}>
      {label ? <FormLabel {...formLabelProps}>{label}</FormLabel> : null}
      <FormGroup {...formGroupProps}>
        {itemsData.map((item: SwitchData, idx: number) => (
          <FormControlLabel
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            name={name}
            label={item.label}
            value={single ? undefined : item.value}
            disabled={item.disabled}
            control={
              <Field
                type="checkbox"
                name={name}
                render={({
                  input: { name: nameField, value, onChange, checked, ...restInput },
                }) => (
                  <MuiSwitch
                    name={nameField}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    disabled={item.disabled}
                    required={required}
                    inputProps={{ required, ...restInput }}
                    {...restSwitches}
                  />
                )}
                {...fieldProps}
              />
            }
            {...formControlLabelProps}
          />
        ))}
      </FormGroup>
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
}
