import MuiDateTimePicker, {
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { showErrorOnChange, ShowErrorFunc } from './Util';
import pickerProviderWrapper from './PickerProvider';

export interface DateTimePickerProps extends Partial<Omit<MuiDateTimePickerProps, 'onChange'>> {
  name: string;
  dateFunsUtils?: any;
  locale?: any;
  fieldProps?: Partial<FieldProps<any, any>>;
  showError?: ShowErrorFunc;
}

interface DateTimePickerWrapperProps extends FieldRenderProps<MuiDateTimePickerProps, HTMLElement> {
  dateFunsUtils?: any;
  locale?: any;
}

function DateTimePickerWrapper(props: DateTimePickerWrapperProps) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    dateFunsUtils,
    locale,
    showError = showErrorOnChange,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  const { helperText, ...lessrest } = rest;

  return pickerProviderWrapper(
    dateFunsUtils,
    <MuiDateTimePicker
      fullWidth
      autoOk
      helperText={isError ? error || submitError : helperText}
      error={isError}
      onChange={onChange}
      name={name}
      value={(value as any) === '' ? null : value}
      {...lessrest}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ inputProps: { [key: string]: any; onBlur: ... Remove this comment to see the full error message
      inputProps={restInput}
      renderInput={(params) => {
        params.error = isError;
        return (
          <TextField
            variant="standard"
            {...params}
            fullWidth
            helperText={isError ? error || submitError : helperText || params.helperText}
          />
        );
      }}
    />,
    locale
  );
}

export function DateTimePicker(props: DateTimePickerProps): React.ReactElement {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      render={(fieldRenderProps) => <DateTimePickerWrapper {...fieldRenderProps} {...rest} />}
      {...fieldProps}
    />
  );
}
