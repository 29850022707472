import { ReactNode } from 'react';

import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormControl,
  FormControlProps,
  FormHelperTextProps,
  InputLabel,
  InputLabelProps,
  MenuItem,
  MenuItemProps,
} from '@mui/material';

import { Field, FieldProps } from 'react-final-form';
import { ErrorMessage, showErrorOnChange, useFieldForErrors, ShowErrorFunc } from './Util';

export interface SelectData {
  label: string;
  value: string | number | string[] | undefined;
  disabled?: boolean;
}

export interface SelectProps extends Partial<Omit<MuiSelectProps, ''>> {
  name: string;
  label?: ReactNode;
  required?: boolean;
  multiple?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
  menuItemProps?: Partial<MenuItemProps>;
  onChange?: (event: React.ChangeEvent<{ name?: string; value: any }>, value: any) => void;
  data?: SelectData[];
  children?: ReactNode;
}

export function Select(props: SelectProps): React.ReactElement {
  const {
    name,
    label,
    data,
    children,
    required,
    multiple,
    helperText,
    fieldProps,
    inputLabelProps,
    formControlProps,
    formHelperTextProps,
    menuItemProps,
    showError = showErrorOnChange,
    onChange: onChangeCallback,
    ...restSelectProps
  } = props;

  if (!data && !children) {
    throw new Error('Please specify either children or data as an attribute.');
  }

  const { variant, fullWidth } = restSelectProps;
  const field = useFieldForErrors(name);
  const isError = showError(field);

  const getChild = () => {
    if (data) {
      return data.map((item) => {
        return (
          <MenuItem
            value={item.value}
            key={item.value}
            disabled={item.disabled}
            {...(menuItemProps as any)}
          >
            {item.label}
          </MenuItem>
        );
      });
    }
    return children;
  };

  const finalChild = getChild();

  return (
    <Field
      name={name}
      render={({ input: { name: nameField, value, onChange, ...restInput } }) => {
        // prevents an error that happens if you don't have initialValues defined in advance
        const finalValue = multiple && !value ? [] : value;
        const labelId = `select-input-${name}`;

        const onChangeFunc = (
          event: React.ChangeEvent<{ name?: string; value: any }>
          // child: React.ReactNode
        ) => {
          onChange(event);

          if (onChangeCallback) {
            onChangeCallback(event, event.target.value);
          }
        };

        return (
          <FormControl
            required={required}
            error={isError}
            fullWidth={fullWidth}
            variant={variant}
            {...formControlProps}
          >
            {!!label && (
              <InputLabel id={labelId} {...inputLabelProps}>
                {label}
              </InputLabel>
            )}
            <MuiSelect
              name={nameField}
              value={finalValue}
              onChange={onChangeFunc}
              multiple={multiple}
              label={label}
              labelId={labelId}
              inputProps={{ required, ...restInput }}
              {...restSelectProps}
            >
              {finalChild}
            </MuiSelect>
            <ErrorMessage
              showError={isError}
              meta={field.meta}
              formHelperTextProps={formHelperTextProps}
              helperText={helperText}
            />
          </FormControl>
        );
      }}
      {...fieldProps}
    />
  );
}
